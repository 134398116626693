<script context="module">
	export async function load({ session, url: { pathname } }) {
		const isLogin = pathname.includes('/auth/login');
		const isReport = pathname.includes('report');
		const isJob = pathname.startsWith('/job');
		const userName = session.userName;
		const skipAuth = isLogin || isReport || isJob;

		if (!session.isLoggedIn && !skipAuth)
			return {
				status: 302,
				redirect: `/auth/login?redirect=${pathname}`
			};

		return {
			props: { skipAuth, userName }
		};
	}
</script>

<script>
	import '../app.css';
	import '/node_modules/flag-icons/css/flag-icons.min.css';
	import { account } from '$utils/appwrite-client';
	import { page, session } from '$app/stores';

	import * as Sentry from '@sentry/svelte';
	import { BrowserTracing } from '@sentry/tracing';

	Sentry.init({
		environment: import.meta.env.VITE_SENTRY_ENV,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 0.3
	});

	export let skipAuth = false;
	export let userName;
</script>

<main class="bg-black min-h-screen w-auto">
	{#if !skipAuth}
		<nav class="bg-black fixed left-0 right-0 bottom-0 sm:relative z-50">
			<div class="px-4 md:px-10">
				<div class="flex items-center justify-between h-16">
					<div class="flex items-center gap-4">
						<a href="/" class={`text-white rounded-md text-m font-medium`}>Notion Import</a>

						<a class={`text-white px-3 py-2 rounded-md text-sm font-medium`} href="/planning">
							Planning
						</a>

						<a
							href="/order/new"
							class={`text-white px-3 py-2 rounded-md text-sm font-medium`}
							class:bg-zinc-700={$page.url.pathname.includes('/order/new')}
							aria-current="page">Create Order</a
						>

						<a
							href="/rut"
							class={`text-white px-3 py-2 rounded-md text-sm font-medium`}
							class:bg-zinc-700={$page.url.pathname.includes('/rut')}
							aria-current="page">RUT Applications</a
						>

						<a
							href="/resources"
							class={`text-white px-3 py-2 rounded-md text-sm font-medium`}
							class:bg-zinc-700={$page.url.pathname.includes('/resources')}
							aria-current="page">Resources</a
						>

						<a
							href="/finance"
							class={`text-white px-3 py-2 rounded-md text-sm font-medium`}
							class:bg-zinc-700={$page.url.pathname.includes('/finance')}
							aria-current="page">Finance</a
						>
					</div>

					<div class="block">
						<div class="ml-4 flex items-center md:ml-6">
							{#if !$session.isLoggedIn}
								<a href={'/auth/login'}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="white"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
										/>
									</svg></a
								>
							{:else}
								<form
									method="post"
									action="/auth/requests/logout"
									on:submit={async () => await account.deleteSession('current')}
								>
									<button
										type="submit"
										class="flex align-center gap-3 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
									>
										<p class="hidden sm:block">{userName ?? ''}</p>
										<!-- Heroicon name: logout -->
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="white"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
											/>
										</svg>
									</button>
								</form>
							{/if}
						</div>
					</div>
				</div>
			</div>
		</nav>

		<div class="self-start p-2 text-white">
			<slot />
		</div>
	{:else}
		<slot />
	{/if}
</main>
